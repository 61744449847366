<template>
	<div>
		<v-menu
			offset-y
			v-model="show"
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on }">
				<Button
					v-on="on"
					class="ms-4"
					v-text="selected.title"
				>
				</Button>
			</template>
			<v-card>
				<v-row>
					<v-col>
						<v-list dense>
							<template
								v-for="(item, index) in range.items"
							>
								<v-list-item
									:key="index"
									@click="change_timerange(item)"
									v-if="item.name !== `customRange`"
								>
									<v-list-item-title>{{ item.title }}</v-list-item-title>
								</v-list-item>
							</template>
						</v-list>
					</v-col>
					<v-col>
						<v-date-picker
							show-week
							class="pa-6"
							first-day-of-week="1"
							v-model="dates"
							no-title
							range
							@change="change_custom_timerange"
						></v-date-picker>
					</v-col>
				</v-row>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
	import { globalMixIn } from '@/App'

	export default {
		data () {
			return {
				show: false,
				dates: null,
				selected: { title: 'All history', timeRange: '', name: 'allHistory' },
				range: {
					items: [
						{ title: 'All history', timeRange: '', name: 'allHistory' },
						{ title: 'Last 365 days', timeRange: '365d', name: 'last365days' },
						{ title: 'Last 90 days', timeRange: '90d', name: 'last90days' },
						{ title: 'Last 31 days', timeRange: '31d', name: 'last31days' },
						{ title: 'Last 7 days', timeRange: '7d', name: 'last7days' },
						{ title: 'Last year', timeRange: 'lastYear', name: 'lastYear' },
						{ title: 'Last quarter', timeRange: 'lastQuarter', name: 'lastQuarter' },
						{ title: 'Last month', timeRange: 'lastMonth', name: 'lastMonth' },
						{ title: 'Last week', timeRange: 'lastWeek', name: 'lastWeek' },
						{ title: 'Yesterday', timeRange: 'yesterday', name: 'yesterday' },
						{ title: 'This year', timeRange: 'thisYear', name: 'thisYear' },
						{ title: 'This quarter', timeRange: 'thisQuarter', name: 'thisQuarter' },
						{ title: 'This month', timeRange: 'thisMonth', name: 'thisMonth' },
						{ title: 'This week', timeRange: 'thisWeek', name: 'thisWeek' },
						{ title: 'Today', timeRange: 'today', name: 'today' },
						{ title: 'Custom range', timeRange: 'customRange', name: 'customRange' },
					],
				},
			}
		},
		name: `ComponentDateRangeSelector`,
		methods: {
			change_timerange(e) {
				let o = {
					params: `timerange=${e.timeRange}`,
					date_range: e.timeRange,
					name: e.name
				}
				this.selected = e
				this.show = false
				this.$emit('selected', o)
			},
			change_custom_timerange(range) {
				let o = {
					params: `timerange=${range[0]}::${this.dayjs(range[1]).add(1,`day`).format(`YYYY-MM-DD`)}`,
					date_range: `${range[0]}::${range[1]}`,
					name: `customRange`
				}
				let e = {
					timeRange: `customRange`,
					name: `customRange`,
					title: `Custom range`,
				}
				this.selected = e
				this.show = false
				this.$emit('selected', o)
			}
		},
		mixins: [globalMixIn],
		mounted() {
			if (this.default) {
				this.selected = this.range.items.find(o => o.name === this.default)
			}
			this.change_timerange(this.selected)
		},
		props: ['default'],
	}
</script>