<template>
	<v-container fluid>
		<v-row align="center">
			<DateRangeSelector @selected="newDateRange" />
			<div class="pl-4 grey--text">{{dateRangeText}}</div>
		</v-row>

		<v-row>
			<v-col>
				<v-select
					:items="utmGroupFilters"
					label="Group by..."
					v-model="utmGroupFilterSelected"
					@change="changeUtmGroupBy"
				></v-select>

				<v-card>
					<v-data-table
						:loading="loading"
						:headers="headers"
						:items="items"
						:options.sync="options"
						:server-items-length="totalItems"
						:footer-props="{'items-per-page-options':[10,25,50]}"
					>
						<template v-slot:[`item.first`]="{ item }">
							{{ dayjs(item.first).format('YYYY-MM-DD HH:mm:ss') }}
						</template>
						<template v-slot:[`item.last`]="{ item }">
							{{ dayjs(item.last).format('YYYY-MM-DD HH:mm:ss') }}
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import DateRangeSelector from '@/components/material/DateRangeSelector'

	export default {
		components: {
			DateRangeSelector,
		},
		data () {
			return {
				utmGroupFilters: [`none`, `utm_source`, `utm_medium`, `utm_campaign`],
				utmGroupFilterSelected: ``,

				dateRange: ``,
				dateRangeParams: ``,
				dateRangeName: ``,
				dateRangeText: ``,

				headers: [
					{ text: `UTM Source`, value: `utm_source` },
					{ text: `UTM Medium`, value: `utm_medium` },
					{ text: `UTM Campaign`, value: `utm_campaign` },
					{ text: `Accounts Created`, value: `count` },
					{ text: `First`, value: `first`, width: `190px` },
					{ text: `Last`, value: `last`, width: `190px` },
				],
				items: [],
				totalItems: 0,
				loading: false,
				options: {
					sortDesc: [true],
					sortBy: [`count`],
				},
			}
		},
		methods: {
			reset() { Object.assign(this.$data, this.$options.data.apply(this)) },
			newDateRange(o) {
				this.reset()
				this.dateRange = o.date_range
				this.dateRangeParams = o.params
				this.dateRangeName = o.name
				this.refresh()
			},
			changeUtmGroupBy() {
				this.refresh()
			},
			refresh() {
				this.loading = true
				this.$httpInt.get(`/v2/statistics/utm-source?${this.dateRangeParams}&group_by=${this.utmGroupFilterSelected}`, { params: this.$paginationParams(this) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			}
		},
		name: `StatisticsUtmSource`,
		watch: {
			'options': {
				handler() {
					this.refresh()
				},
				deep: true,
			},
		},
	}

</script>
